@import '~antd/dist/antd.less';

.yarl__slide {
  padding: 0 !important;
  margin: 0 !important;
}

.yarl__container {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

* {
    box-sizing: border-box;
    outline: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Workaround for https://github.com/ant-design/ant-design/issues/32871
.@{pageheader-prefix-cls} {
    &-heading {
        &-title {
            line-height: @height-base;
        }
    }
}

// Allow radio button content to be block based
.@{radio-prefix-cls-button-wrapper} {
  > span {
    display: block;
    width: 100%;
  }
}

.ins-cursor-pointer {
    cursor: pointer;
}

.ins-vertical-align-top {
    vertical-align: top;
}

.@{upload-prefix-cls}-list {
  &-item {
    &-card-actions {
      &-btn.@{ant-prefix}-btn-sm {
        height: auto;
      }
    }
  }
}


// Normally, progress bar overflows delete button (because of position: absolute)
.@{upload-prefix-cls}-list {
  &-item {
    &-progress {
      bottom: 8px !important;
      width: calc(100% - 80px) !important;
    }
  }
}
@layout-body-background: #f0f2f5;@body-background: @layout-body-background;@primary-color: #00A3CC;@primary-1: #e6f7ff;@layout-sider-background: @primary-color;@menu-item-font-size: 16px;@menu-dark-bg: @layout-sider-background;@menu-dark-color: #fff;@menu-dark-item-active-bg: #3cbcdd;@menu-dark-item-hover-bg: #007a99;@menu-dark-inline-submenu-bg: #00A3CC;@menu-item-boundary-margin: 0;@menu-item-vertical-margin: 0;@menu-inline-toplevel-item-height: 46px;@menu-item-height: 46px;@modal-mask-bg: rgb(0 0 0 / 50%);@modal-body-padding: 30px;@animation-duration-slow: 0.1s;@animation-duration-base: 50ms;@animation-duration-fast: 40ms;@height-base: 44px;@height-lg: 52px;@height-sm: 36px;@padding-lg: 30px;@padding-md: 22px;@padding-sm: 18px;@padding-xs: 12px;@padding-xss: 8px;@collapse-header-padding: @padding-xss @padding-xs;@collapse-content-padding: @padding-sm;@result-title-font-size: 18px;@result-icon-font-size: 58px;@pagination-item-size: 38px;@tabs-vertical-padding: @padding-xss @padding-md;@tabs-vertical-margin: @padding-xss 0 0 0;@popover-arrow-offset-horizontal: 6px;@border-radius-base: 4px;@table-expanded-row-bg: #fff;